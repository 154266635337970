import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store/index'
const routerPush = VueRouter.prototype.push
// 重写push函数
VueRouter.prototype.push = function push(location) {

  // 这个if语句在跳转相同路径的时候，在路径末尾添加新参数（一些随机数字）
  // 用来触发watch
  if (typeof (location) == "string") {
    let Separator = "&";
    if (location.indexOf('?') == -1) { Separator = '?'; }
    location = location + Separator + "random=" + Math.random();
  }

  // 这个语句用来解决报错
  // 调用原来的push函数，并捕获异常
  return routerPush.call(this, location).catch(error => error)
}
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    meta: {
      title: '登录'
    },
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/forget',
    name: 'forget',
    meta: {
      title: '忘记密码'
    },
    component: () => import('../views/login/forgetPsw.vue')
  },
  {
    path: '/signIn',
    name: 'signIn',
    meta: {
      title: '注册'
    },
    component: () => import('../views/login/signIn.vue')
  },
  {
    path: '/signInSuccess',
    name: 'signInSuccess',
    meta: {
      title: '注册提交'
    },
    component: () => import('../views/login/signInSuccess.vue')
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '广联汇'
    },
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/searchGoods',
    name: 'searchGoods',
    meta: {
      title: '搜索商品'
    },
    component: () => import('../views/home/searchGoods.vue')
  },
  {
    path: '/searchStore',
    name: 'searchStore',
    meta: {
      title: '搜索商品'
    },
    component: () => import('../views/home/searchStore.vue')
  },
  {
    path: '/store',
    name: 'store',
    meta: {
      title: '店铺'
    },
    component: () => import('../views/store/index.vue')
  },
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    meta: {
      title: '商品详情'
    },
    component: () => import('../views/goods/index.vue')
  },
  {
    path: '/order',
    name: 'order',
    meta: {
      title: '订单'
    },
    component: () => import('../views/order/index.vue')
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    meta: {
      title: '订单'
    },
    component: () => import('../views/order/orderDetail.vue')
  },
  {
    path: '/chooseAddress',
    name: 'chooseAddress',
    meta: {
      title: '选择地址'
    },
    component: () => import('../views/order/chooseAddress.vue')
  },
  {
    path: '/mine',
    name: 'mine',
    meta: {
      title: '我的'
    },
    component: () => import('../views/mine/index.vue')
  },
  {
    path: '/account',
    name: 'account',
    meta: {
      title: '个人资料'
    },
    component: () => import('../views/mine/account.vue')
  },
  {
    path: '/poket',
    name: 'poket',
    meta: {
      title: '我的账户'
    },
    component: () => import('../views/mine/poket.vue')
  },
  {
    path: '/recharge',
    name: 'recharge',
    meta: {
      title: "充值"
    },
    component: () => import('../views/mine/recharge.vue')
  },
  {
    path: '/rechargeTips',
    name: 'rechargeTips',
    meta: {
      title: "充值成功"
    },
    component: () => import('../views/mine/rechargeTips.vue')
  },
  {
    path: '/address',
    name: 'address',
    meta: {
      title: "收货地址"
    },
    component: () => import('../views/address/index.vue')
  },
  {
    path: '/addAddress',
    name: 'addAddress',
    meta: {
      title: "新增地址"
    },
    component: () => import('../views/address/add.vue')
  },
  {
    path: '/changePhone',
    name: 'changePhone',
    meta: {
      title: "更换手机号"
    },
    component: () => import('../views/changePhone/index.vue')
  },
  {
    path: '/editPsw',
    name: 'editPsw',
    meta: {
      title: "修改登录密码"
    },
    component: () => import('../views/editPsw/index.vue')
  },
  {
    path: '/editPayPsw',
    name: 'editPayPsw',
    meta: {
      title: "修改支付密码"
    },
    component: () => import('../views/editPayPsw/index.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    meta: {
      title: "关于我们"
    },
    component: () => import('../views/aboutUs/index.vue')
  },
  {
    path: '/message',
    name: 'message',
    meta: {
      title: "消息通知"
    },
    component: () => import('../views/message/index.vue')
  },
  {
    path: '/dynamic',
    name: 'dynamic',
    meta: {
      title: "动态消息"
    },
    component: () => import('../views/message/dynamic.vue')
  },
  {
    path: '/systemMessage',
    name: 'systemMessage',
    meta: {
      title: "系统消息"
    },
    component: () => import('../views/message/systemMessage.vue')
  },
  {
    path: '/collection',
    name: 'collection',
    meta: {
      title: "收藏店铺"
    },
    component: () => import('../views/collection/index.vue')
  },
  {
    path: '/opinion',
    name: 'opinion',
    meta: {
      title: "意见反馈"
    },
    component: () => import('../views/opinion/index.vue')
  },
  {
    path: '/refund',
    name: 'refund',
    meta: {
      title: "退货退款"
    },
    component: () => import('../views/order/refund.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = to.meta.title
  }
  store.commit('getBeforePath', from.path)
  next();
})
export default router
